<!-- =========================================================================================
   File Name: categoryView.vue
    Description: Category
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/master">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <!-- WORK TYPE FORMS -->
      <div
        v-if="
          permissionPageAccessArry.includes(PermissionVariables.PriorityAdd) ||
            loginUserRole == 'Admin'
        "
        class="vx-col w-full mb-base"
      >
        <!-- v-if="checkPermission(['workType','GET'])" -->
        <priorityForm />
      </div>

      <!-- WORK TYPE LIST -->
      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab
            v-if="
              permissionPageAccessArry.includes(
                PermissionVariables.PriorityAdd
              ) ||
                permissionPageAccessArry.includes(
                  PermissionVariables.PriorityView
                ) ||
                loginUserRole == 'Admin'
            "
            label="Priority List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div class="tab-text">
              <!-- WORK TYPE LIST -->
              <div>
                <!-- v-if="checkPermission(['workType','POST'])" -->
                <priorityList />
              </div>
            </div>
          </vs-tab>
          <!-- <vs-tab label="Archive List" icon-pack="feather" icon="icon-box">
                  <div>
              <priorityList listType="archive" />
            </div>
          </vs-tab> -->
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import priorityForm from "@/views/apps/priorityForm/priorityForm.vue";
import priorityList from "@/views/apps/priorityForm/priorityList.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      isActive: false,
      loginUserRole: window.localStorage.getItem("UserRole"),
      PermissionVariables: PermissionVariables,
      analyticsData: [],
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ]
    };
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    }
  },
  components: {
    priorityForm,
    priorityList
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
